import React, { useState, useEffect } from "react"
import Papa from "papaparse";
import vis from '../../content/assets/vis.png'
import temp from '../../content/assets/temp.png'
import {Col, Row } from "react-bootstrap"


const green = {
  color: '#55ab68',
}
const box = {
  border: '2px solid #005677',
  padding: '10px',
  borderRadius: '3px',
}
const limitheight = {
  maxHeight: '50px',
  float: 'right',
}

const Watertemp = () => {
  const [data, setData] = useState({}); 
  useEffect(() => {
    Papa.parse("https://docs.google.com/spreadsheets/d/e/2PACX-1vTDPPa0A3rV_5hlbfxi88wbKjy_KzfX-2rM4LBFTMyrGFGwxEGU0hB-vA1BZuBVSFrASjJs6WHl3ghs/pub?gid=0&single=true&output=csv", {
      download: true,
      header: true,
      complete: (results) => {
        setData(results.data);
      },
    });      
  }, [])
  //console.log(data);
  return(
  <Row>
    <Col md={6}>
      <div className="mb-3" style={box}>
        <img height='50' alt='Temperature' src={temp} style={limitheight} />
        <b>
          SURFACE WATER TEMP <span style={green}>{data.length > 0 ? "~" +data[0].surfaceWaterTemp + "℃" : "Loading..."}</span>
        </b>
        <br />
        <b>
          MID-LEVEL WATER TEMP <span style={green}>{data.length > 0 ? "~" + data[0].midLevelWaterTemp + "℃" : "Loading..."}</span>
        </b>
      </div>
    </Col>
    <Col md={6}>
      <div className="mb-3" style={box}>
        <img height='50' alt='Visibility' src={vis} style={limitheight} />
        <b>
          WATER VISIBILITY IS <span style={green}>{data.length > 0 ? data[0].waterVisibilty : "Loading..."}</span>
        </b>
        <br />
        <b>
          VISIBILITY DISTANCE IS <span style={green}>{data.length > 0 ? "~" + data[0].visibilityDistance + "m" : "Loading..."}</span>
        </b>
      </div>
    </Col>
  </Row>
  )
}

export default Watertemp
